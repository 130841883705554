import { routes } from '~constants/routes'

export function generateWorkspacePathPrefix(
  workspaceId: string,
  albumId: string,
) {
  return `/workspaces/${workspaceId}/albums/${albumId}`
}

export const isGalleryPath = (path: string) =>
  path.startsWith(routes.gallery + '/')
