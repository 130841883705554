import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const breadcrumbHelper = createMultiStyleConfigHelpers(['link'])

const baseStyle = breadcrumbHelper.definePartsStyle({
  link: {
    _activeLink: {
      color: 'interaction.main.active',
    },
    '&:not([aria-current=page])': {
      cursor: 'pointer',
      color: 'base.content.strong',
    },
  },
})
export const breadcrumbTheme = breadcrumbHelper.defineMultiStyleConfig({
  baseStyle,
})
