import { FormControl, Stack } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useIsDesktop } from '~hooks/useIsDesktop'
import { EMAIL_ERROR_CONTACT_US_MESSAGE } from '~shared/constants'

import { ResendOtpButton } from './ResendOtpButton'

const schema = z.object({
  token: z
    .string()
    .trim()
    .min(1, 'OTP is required.')
    .regex(/^[0-9\b]+$/, { message: 'Only numbers are allowed.' })
    .length(6, 'Please enter a 6 digit OTP.'),
})

export type OtpFormInputs = {
  token: string
}

interface OtpFormProps {
  email: string
  onSubmit: (inputs: OtpFormInputs) => Promise<void>
  onResendOtp: () => Promise<void>
  resendOtpCountdown: number
  resetEmail: () => void
}

export interface OtpError {
  json: {
    message: string
    isReattemptAllowed: boolean
  }
}

export const OtpForm = ({
  email,
  onSubmit,
  onResendOtp,
  resendOtpCountdown,
  resetEmail,
}: OtpFormProps): JSX.Element => {
  const [isReattemptAllowed, setIsReattemptAllowed] = useState<boolean>(true)
  const {
    handleSubmit,
    register,
    formState,
    setError,
    clearErrors,
    resetField,
  } = useForm<OtpFormInputs>({
    resolver: zodResolver(schema),
  })

  const isDesktop = useIsDesktop()

  const onSubmitForm = async (inputs: OtpFormInputs) => {
    return onSubmit(inputs).catch((e: OtpError) => {
      setError('token', { type: 'server', message: e.json.message })
      setIsReattemptAllowed(e.json.isReattemptAllowed)
    })
  }

  const onOtpSuccess = () => {
    clearErrors()
    resetField('token')
    setIsReattemptAllowed(true)
  }
  const onOtpError = (e: OtpError) => {
    setError('token', { type: 'server', message: e.json.message })
    setIsReattemptAllowed(e.json.isReattemptAllowed)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl isRequired isInvalid={!!formState.errors.token} mb="2.5rem">
        <FormLabel htmlFor="token">{`Enter OTP sent to ${email}`}</FormLabel>
        <Input
          type="text"
          maxLength={6}
          inputMode="numeric"
          autoComplete="one-time-code"
          autoFocus
          focusBorderColor={
            formState.errors.token
              ? 'interaction.critical.default'
              : 'base.divider.strong'
          }
          borderColor="base.divider.strong"
          variant="filled"
          backgroundColor="utility.ui"
          _hover={{ bg: 'interaction.sub-subtle.default' }}
          textColor="base.content.strong"
          {...register('token')}
        />
        <FormErrorMessage>{formState.errors.token?.message}</FormErrorMessage>
      </FormControl>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          type="submit"
          border="none"
          color="base.content.inverse"
          _hover={{ bg: 'interaction.main.hover' }}
          isDisabled={!isReattemptAllowed}
        >
          Sign in
        </Button>
        {formState.errors.token?.message?.includes(
          EMAIL_ERROR_CONTACT_US_MESSAGE,
        ) ? (
          <Button
            type="button"
            variant="clear"
            colorScheme="primary"
            _hover={{ bg: 'interaction.tinted.inverse.hover' }}
            onClick={resetEmail}
          >
            <u style={{ textDecoration: 'none' }}>Back to login</u>
          </Button>
        ) : (
          <ResendOtpButton
            resendOtpCountdown={resendOtpCountdown}
            onResendOtp={onResendOtp}
            onOtpSuccess={onOtpSuccess}
            onOtpError={onOtpError}
          />
        )}
      </Stack>
    </form>
  )
}
