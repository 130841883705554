import '@fontsource/ibm-plex-mono'
import 'inter-ui/inter.css'

import { ThemeProvider } from '@opengovsg/design-system-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useRef } from 'react'

import { theme } from '~/theme'

import { AppRouter } from './AppRouter'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
})

export const App = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider
        theme={theme}
        resetCSS
        toastOptions={{
          portalProps: {
            containerRef: ref,
          },
        }}
      >
        <AppRouter />
        {
          // Allow toast to be in front of modal
          // https://github.com/chakra-ui/chakra-ui/issues/6276
          <div ref={ref} />
        }
      </ThemeProvider>
    </QueryClientProvider>
  )
}
