import { Button, Stack, Text } from '@chakra-ui/react'
import { WretchError } from 'wretch/resolver'

import { ReactComponent as PinpointLogo } from '~assets/pinpoint-logo.svg'
import { ReactComponent as SharedWorkspaceNoAccess } from '~assets/SharedWorkspaceNoAccess.svg'
import { ErrorLayout } from '~features/errorPages/ErrorLayout'
import {
  SHAREABLE_LINK_FORBIDDEN,
  SHAREABLE_LINK_NOT_FOUND,
  WORKSPACE_NOT_FOUND,
} from '~features/gallery/constants'
import { useDisclosureStore } from '~features/gallery/useDisclosureStore'

import { useSetCurrentWorkspaceToPersonal } from '../../gallery/useWorkspaceStore'

export const WorkspaceFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: WretchError
  resetErrorBoundary: () => void
}): JSX.Element => {
  const onRenameWorkspaceModalDisclosureClose =
    useDisclosureStore.use.onRenameWorkspaceModalDisclosureClose()
  const onDeleteWorkspaceModalDisclosureClose =
    useDisclosureStore.use.onDeleteWorkspaceModalDisclosureClose()
  const onWorkspaceLogsModalClose =
    useDisclosureStore.use.onWorkspaceLogsModalDisclosureClose()
  const setCurrentWorkspaceToPersonal = useSetCurrentWorkspaceToPersonal()
  switch (error.cause) {
    case WORKSPACE_NOT_FOUND:
    case SHAREABLE_LINK_FORBIDDEN:
      return (
        <Stack
          px={{ base: '1.75rem', lg: '8rem' }}
          gap="1.5rem"
          py="4.5rem"
          alignItems="center"
          h="100vh"
        >
          <PinpointLogo width="auto" height="2.5rem" />
          <SharedWorkspaceNoAccess width="auto" height="100%" />
          {error.cause === WORKSPACE_NOT_FOUND ? (
            <Text textAlign="center" textStyle="subhead-1">
              Whoops, it looks like you have no access to this shared workspace
              or this workspace does not exist.
              <br />
              <br />
              Please check if the workspace ID in the URL you entered was
              correct, or contact the workspace admin for help to be added to
              this workspace.
            </Text>
          ) : (
            <Text textAlign="center" textStyle="subhead-1">
              Whoops, it looks like you have no access to this shared link.
              <br />
              <br />
              Please make sure the URL is entered correctly, and confirm with
              the person who shared this link that you&apos;ve been granted
              access to the workspace.
            </Text>
          )}
          <Button
            maxWidth="15rem"
            onClick={() => {
              // closing all workspace admin modals
              onRenameWorkspaceModalDisclosureClose()
              onDeleteWorkspaceModalDisclosureClose()
              onWorkspaceLogsModalClose()
              setCurrentWorkspaceToPersonal()
              resetErrorBoundary()
            }}
            textStyle="subhead-2"
          >
            Return to your workspace
          </Button>
        </Stack>
      )
    case SHAREABLE_LINK_NOT_FOUND:
      return (
        <ErrorLayout
          title="404"
          message="The shareable link does not exist or is expired."
          resetErrorBoundary={resetErrorBoundary}
        />
      )
    default:
      return (
        <ErrorLayout
          title={error.status?.toString()}
          message="Sorry, something went wrong."
          resetErrorBoundary={resetErrorBoundary}
        />
      )
  }
}
