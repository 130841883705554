import { Box } from '@chakra-ui/react'
import { PropsWithChildren, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useParams,
} from 'react-router-dom'

import { routes } from '~constants/routes'
import { WorkspaceFallback } from '~features/errorPages/generalError'
import { AuthProvider } from '~lib/auth'
import { useRum } from '~lib/rum'
import { lazyImport } from '~utils/lazyImport'

const { AuthRoutes } = lazyImport(() => import('~features/auth'), 'AuthRoutes')
const { LandingRoutes } = lazyImport(
  () => import('~features/landing'),
  'LandingRoutes',
)
const { DashboardRoutes } = lazyImport(
  () => import('~features/dashboard'),
  'DashboardRoutes',
)
const { HealthRoutes } = lazyImport(
  () => import('~features/health'),
  'HealthRoutes',
)
const { GalleryRoutes } = lazyImport(
  () => import('~features/gallery'),
  'GalleryRoutes',
)
const { GalleryRedirectRoute } = lazyImport(
  () => import('~features/gallery'),
  'GalleryRedirectRoute',
)
const { NotFoundRoutes } = lazyImport(
  () => import('~features/errorPages/notFound'),
  'NotFoundRoutes',
)
const { ShareRoutes } = lazyImport(
  () => import('~features/gallery'),
  'ShareRoutes',
)

const ErrorBoundaryLayout = () => {
  const { workspaceId } = useParams()
  const { awsRum } = useRum()
  const logError = (error: Error) => {
    awsRum?.recordError(error)
  }
  return (
    // key=workspaceId resets the error boundary every time the workspaceId in path params changes
    <ErrorBoundary
      FallbackComponent={WorkspaceFallback}
      key={workspaceId}
      onError={logError}
    >
      <Outlet />
    </ErrorBoundary>
  )
}

const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<ErrorBoundaryLayout />}>
            <Route path={routes.index} element={<LandingRoutes />} />
            <Route path={routes.dashboard} element={<DashboardRoutes />} />
            <Route path={routes.login} element={<AuthRoutes />} />
            <Route path={routes.health} element={<HealthRoutes />} />
            <Route path={routes.gallery} element={<GalleryRedirectRoute />} />
            <Route
              path={routes.galleryWithWorkspaceId}
              element={<GalleryRoutes />}
            />
            <Route
              path={routes.galleryWithWorkspace}
              element={<GalleryRoutes />}
            />
            <Route path={routes.shareLink} element={<ShareRoutes />} />
            <Route path="*" element={<NotFoundRoutes />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}

const WithSuspense = ({ children }: PropsWithChildren) => (
  <Suspense fallback={<Box bg="neutral.100" minH="$100vh" w="100vw" />}>
    {children}
  </Suspense>
)

export const AppRouter = (): JSX.Element => {
  return (
    <WithSuspense>
      <Router />
    </WithSuspense>
  )
}
