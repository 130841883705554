import { drawerAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const drawerhelper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = drawerhelper.definePartsStyle({
  overlay: {
    backdropFilter: 'auto',
    backdropBlur: '2px',
    backdropInvert: '10%',
    background: 'none',
  },
})

export const drawerTheme = drawerhelper.defineMultiStyleConfig({
  baseStyle,
})
