import { Banner as OGPBanner } from '@opengovsg/design-system-react'
import { useQuery } from '@tanstack/react-query'

import { BannerDto } from '~shared/types/banner.dto'

import { GET_BANNER } from '../../constants/queryKeys'
import { api } from '../../lib/api'
export const Banner = () => {
  const { data } = useQuery(
    [GET_BANNER],
    () => api.url(`/banner`).get().json<BannerDto>(),
    {
      staleTime: 60000,
    },
  )
  if (data?.message) return <OGPBanner>{data.message}</OGPBanner>
  return null
}
