import { CreateToastFnReturn, UseToastOptions } from '@chakra-ui/react'
import { useToast as useDSToast } from '@opengovsg/design-system-react'

/**
 * Defaults from OGP design system toast:
 *   status: success
 *   duration: 6000
 *   position: top
 * https://github.com/opengovsg/design-system/blob/main/react/src/Toast/useToast.ts
 */
export const useToast = ({
  isClosable = true,
  containerStyle: containerStyleProps,
  ...props
}: UseToastOptions = {}): CreateToastFnReturn => {
  return useDSToast({
    isClosable,
    containerStyle: { wordBreak: 'break-word', ...containerStyleProps },
    ...props,
  })
}
