import { FormControl, Link, Stack, Text } from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@opengovsg/design-system-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useIsDesktop } from '~hooks/useIsDesktop'
import { PINPOINT_PRIVACY_URL, PINPOINT_TOU_URL } from '~shared/constants/links'
import { isGovSgEmail } from '~shared/decorators/is-gov-sg-email'

const schema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Please enter an email address.')
    .email({ message: 'Please enter a valid email address.' })
    .refine(isGovSgEmail, {
      message: 'Please sign in with a gov.sg email address.',
    }),
})

export type LoginFormInputs = {
  email: string
}
interface LoginFormProps {
  onSubmit: (inputs: LoginFormInputs) => Promise<void>
}

export const LoginForm = ({ onSubmit }: LoginFormProps): JSX.Element => {
  const onSubmitForm = async (inputs: LoginFormInputs) => {
    return onSubmit(inputs).catch((e: { json: { message: string } }) => {
      setError('email', { type: 'server', message: e.json.message })
    })
  }

  const { handleSubmit, register, formState, setError } =
    useForm<LoginFormInputs>({
      resolver: zodResolver(schema),
    })

  const isDesktop = useIsDesktop()

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form noValidate onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl
        isRequired
        isInvalid={!!formState.errors.email}
        isReadOnly={formState.isSubmitting}
        mb="1rem"
      >
        <FormLabel
          htmlFor="email"
          fontSize="1rem"
          textColor="base.content.medium"
        >
          Log into Pinpoint with a gov.sg email
        </FormLabel>
        <Input
          autoComplete="email"
          autoFocus
          placeholder="e.g. user@agency.gov.sg"
          focusBorderColor={
            formState.errors.email
              ? 'interaction.critical.default'
              : 'base.divider.strong'
          }
          borderColor="base.divider.strong"
          variant="filled"
          backgroundColor="utility.ui"
          _hover={{ bg: 'interaction.sub-subtle.default' }}
          textColor="base.content.strong"
          fontSize="1rem"
          {...register('email')}
        />
        <FormErrorMessage>{formState.errors.email?.message}</FormErrorMessage>
      </FormControl>

      <Text textStyle="subhead-2" mb="2.25rem">
        By signing into Pinpoint, you acknowledge that you have read,
        understood, and agreed to abide by our&nbsp;
        <Link href={PINPOINT_TOU_URL}>Terms of Use</Link> and&nbsp;
        <Link href={PINPOINT_PRIVACY_URL}>Privacy Policy</Link>
      </Text>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: '1.5rem', lg: '2.5rem' }}
        align="center"
      >
        <Button
          isFullWidth={!isDesktop}
          isLoading={formState.isSubmitting}
          isDisabled={!!formState.errors.email}
          type="submit"
          background="interaction.main.default"
          color="base.content.inverse"
          _hover={{ bg: 'interaction.main.hover' }}
          border="none"
          fontSize="1rem"
        >
          Sign in
        </Button>
      </Stack>
    </form>
  )
}
