import { Box, Button, Center, Spacer, Text, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import { AppFooter } from '~/app/Footer/AppFooter'
import { ReactComponent as PinpointLogo } from '~assets/pinpoint-logo.svg'
import { routes } from '~constants/routes'

export const ErrorLayout = ({
  title,
  message,
  resetErrorBoundary,
}: {
  title: string
  message: string
  resetErrorBoundary?: () => void
}): JSX.Element => {
  return (
    <Box width="100vw" height="100vh">
      <Center position="absolute" width="100%" height="100%">
        <Text
          fontSize={{ base: '100px', sm: '150px', md: '200px', lg: '400px' }}
          color={'base.canvas.backdrop'}
          fontWeight={600}
          userSelect="none"
        >
          {title}
        </Text>
      </Center>
      <VStack zIndex="banner" width="100%" height="100%">
        <VStack
          zIndex="inherit"
          height="30%"
          justifyContent="space-around"
          marginBottom="32"
        >
          <PinpointLogo width="11rem" height="2.75rem" />
        </VStack>
        <VStack zIndex="inherit" height="70%" spacing="12">
          <Text
            fontSize={{ base: '20px', lg: '40px' }}
            fontWeight="600"
            textAlign="center"
            lineHeight="48px"
            wordBreak="break-word"
          >
            {message}
          </Text>
          <Button
            variant="link"
            background="interaction.main.default"
            color="base.content.inverse"
            padding="10px 20px"
            _hover={{ bg: 'interaction.main.hover' }}
            border="none"
            maxW="100%"
            onClick={resetErrorBoundary}
          >
            <Link to={routes.login}>Go to the homepage</Link>
          </Button>
        </VStack>
        <Spacer />
        <AppFooter variant="compact" bg="base.canvas.alt" />
      </VStack>
    </Box>
  )
}
