export const RUM_APPLICATION_ID: string =
  process.env.REACT_APP_RUM_APPLICATION_ID ?? ''

export const APPLICATION_VERSION: string =
  process.env.REACT_APP_APPLICATION_VERSION ?? ''

export const RUM_IDENTITY_POOL_ID: string =
  process.env.REACT_APP_RUM_IDENTITY_POOL_ID ?? ''

export const RUM_SESSION_SAMPLE_RATE: number = process.env
  .REACT_APP_RUM_SESSION_SAMPLE_RATE
  ? Number(process.env.REACT_APP_RUM_SESSION_SAMPLE_RATE)
  : 0
