import { ToastId } from '@chakra-ui/react'
import { getReadableFileSize } from '@opengovsg/design-system-react'
import { format } from 'date-fns'
import { useRef } from 'react'
import { WretchError } from 'wretch/resolver'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import useToast from '~components/useToast'
import { useRumTimedEvent } from '~hooks/useRumTimedEvent'
import { useSetWorkspaceIdPathParam } from '~hooks/useSetPathParams'
import { api } from '~lib/api'
import { CUSTOM_RUM_EVENTS } from '~lib/rum'
import { MAX_MULTIPLE_DOWNLOAD_SIZE } from '~shared/constants'
import { AlbumResponseDto } from '~shared/types/albums.dto'
import { WorkspaceResponseDto } from '~shared/types/workspaces.dto'
import { createSelectors } from '~utils/createSelectors'
import { generateWorkspacePathPrefix } from '~utils/workspacePath'

import { WORKSPACE_NOT_FOUND } from './constants'
import { useDisclosureStore } from './useDisclosureStore'
import { useFiltersStore } from './useFiltersStore'

export type WorkspaceStore = {
  currentWorkspace: WorkspaceResponseDto | null
  setCurrentWorkspace: (workspace: WorkspaceResponseDto | null) => void
  allWorkspaces: WorkspaceResponseDto[]
  setAllWorkspaces: (workspaces: WorkspaceResponseDto[]) => void
  currentAlbum: AlbumResponseDto | null
  setCurrentAlbum: (album: AlbumResponseDto | null) => void
  checkedMedia: CheckedMedia
  setCheckedMedia: (checkedMedia: CheckedMedia) => void
  setCheckedMediaCallback: (fn: (prev: CheckedMedia) => CheckedMedia) => void
  isExpandMediaMode: boolean
  setIsExpandMediaMode: (isExpandMediaMode: boolean) => void
  setIsExpandMediaModeCallback: (fn: (prev: boolean) => boolean) => void
  isDirty: boolean
  setIsDirty: (isDirty: boolean) => void
  isDownloadInProgress: boolean
  setIsDownloadInProgress: (isDownloadInProgress: boolean) => void
  isSelectState: boolean
  setIsSelectState: (isSelectState: boolean) => void
  setIsSelectStateCallback: (fn: (prev: boolean) => boolean) => void
}

export type CheckedMedia = {
  ids: Set<string>
  totalSize: number
}

export const defaultCheckedMedia: CheckedMedia = {
  ids: new Set<string>(),
  totalSize: 0,
}

const WorkspaceStore = create<WorkspaceStore>()(
  devtools(
    (set) => ({
      allWorkspaces: [],
      setAllWorkspaces: (allWorkspaces: WorkspaceResponseDto[]) =>
        set({ allWorkspaces: [...allWorkspaces] }),
      currentWorkspace: null,
      setCurrentWorkspace: (currentWorkspace: WorkspaceResponseDto | null) =>
        set({
          currentWorkspace,
        }),
      currentAlbum: null,
      setCurrentAlbum: (currentAlbum: AlbumResponseDto | null) =>
        set({
          currentAlbum,
        }),
      checkedMedia: defaultCheckedMedia,
      setCheckedMedia: (checkedMedia: CheckedMedia) =>
        set({
          checkedMedia,
        }),
      setCheckedMediaCallback: (fn: (prev: CheckedMedia) => CheckedMedia) => {
        set((state) => ({ checkedMedia: fn(state.checkedMedia) }))
      },
      isExpandMediaMode: false,
      setIsExpandMediaMode: (isExpandMediaMode: boolean) =>
        set({ isExpandMediaMode }),
      setIsExpandMediaModeCallback: (fn: (prev: boolean) => boolean) => {
        set((state) => ({ isExpandMediaMode: fn(state.isExpandMediaMode) }))
      },
      isDirty: false,
      setIsDirty: (isDirty: boolean) => set({ isDirty }),
      isDownloadInProgress: false,
      setIsDownloadInProgress: (isDownloadInProgress: boolean) =>
        set({
          isDownloadInProgress,
        }),
      isSelectState: false,
      setIsSelectState: (isSelectState: boolean) =>
        set({
          isSelectState,
        }),
      setIsSelectStateCallback: (fn: (prev: boolean) => boolean) => {
        set((state) => ({ isSelectState: fn(state.isSelectState) }))
      },
    }),
    { enabled: process.env.NODE_ENV !== 'production' },
  ),
)

/* 
  Derived values, put into hooks
  https://github.com/pmndrs/zustand/issues/108
*/
export const useIsAbleToDownload: () => boolean = () => {
  const checkedMedia = useWorkspaceStore((state) => state.checkedMedia)
  return (
    checkedMedia.ids.size == 1 ||
    checkedMedia.totalSize <= MAX_MULTIPLE_DOWNLOAD_SIZE
  )
}

export const useGetReadableTotalSizeOfCheckedMedia: () => string = () => {
  const checkedMediaTotalSize = useWorkspaceStore.use.checkedMedia().totalSize
  return getReadableFileSize(checkedMediaTotalSize)
}

/* 
  Hooks for these set functions as they require the use of other hooks such as useToast
  cannot invoke hooks inside a zustand store as it exists outside React
  https://stackoverflow.com/questions/76908725/i-need-to-call-a-hook-inside-a-zustand-action
*/
export const useSetCurrentWorkspaceById: () => (id: string) => void = () => {
  const allWorkspaces = useWorkspaceStore((state) => state.allWorkspaces)
  const currentWorkspace = useWorkspaceStore((state) => state.currentWorkspace)
  const setCurrentWorkspace = useWorkspaceStore(
    (state) => state.setCurrentWorkspace,
  )
  const setCurrentAlbum = useWorkspaceStore((state) => state.setCurrentAlbum)
  const toast = useToast()
  return (id) => {
    if (allWorkspaces) {
      const targetWorkspace = allWorkspaces.find(
        (workspace) => workspace.id === id,
      )
      if (targetWorkspace == undefined) {
        toast({
          title: `Workspace does not exist / You do not have access to this workspace`,
          status: 'error',
        })
        throw new Error(
          'Workspace does not exist / You do not have access to this workspace',
          { cause: WORKSPACE_NOT_FOUND },
        )
      } else {
        if (targetWorkspace.id === currentWorkspace?.id) return // do nothing if target is current
        setCurrentAlbum(null) // reset the album first to prevent fetches between unsynced workspace-albums
        setCurrentWorkspace(targetWorkspace)
      }
    }
  }
}

export const useCloseEditDrawer: () => () => void = () => {
  const setIsExpandMediaMode = useWorkspaceStore.use.setIsExpandMediaMode()
  const onEditDrawerClose = useDisclosureStore.use.onEditDrawerClose()
  return () => {
    setIsExpandMediaMode(false)
    onEditDrawerClose()
  }
}

export const useSetCurrentWorkspaceToPersonal: () => () => void = () => {
  const allWorkspaces = useWorkspaceStore.use.allWorkspaces()
  const setCurrentAlbum = useWorkspaceStore.use.setCurrentAlbum()
  const setCheckedMedia = useWorkspaceStore.use.setCheckedMedia()
  const closeEditDrawer = useCloseEditDrawer()
  const clearAllFilters = useFiltersStore.use.clearAllFilters()
  const setWorkspaceIdPathParam = useSetWorkspaceIdPathParam()
  return () => {
    if (allWorkspaces.length > 0) {
      setCurrentAlbum(null) // reset the album first to prevent fetches between unsynced workspace-albums
      setWorkspaceIdPathParam(
        allWorkspaces.find((workspace) => workspace.isDefault)?.id || null,
      )
      clearAllFilters()
      setCheckedMedia(defaultCheckedMedia)
      closeEditDrawer()
    }
  }
}

export const useHandleChangeWorkspace: () => (
  workspaceId: string,
) => void = () => {
  const closeEditDrawer = useCloseEditDrawer()
  const setCheckedMedia = useWorkspaceStore.use.setCheckedMedia()
  const setWorkspaceIdPathParam = useSetWorkspaceIdPathParam()
  const clearAllFilters = useFiltersStore.use.clearAllFilters()
  const onDirtyModalDisclosureOpen =
    useDisclosureStore.use.onDirtyModalDisclosureOpen()
  const isDirty = useWorkspaceStore.use.isDirty()
  return (workspaceId: string) => {
    // if dirty open modal
    if (isDirty) return onDirtyModalDisclosureOpen()
    // else shift workspace and reset gallery states
    setWorkspaceIdPathParam(workspaceId)
    clearAllFilters()
    setCheckedMedia(defaultCheckedMedia)
    closeEditDrawer()
  }
}

export const useHandleDownload: () => () => void = () => {
  const currentWorkspace = useWorkspaceStore.use.currentWorkspace()
  const currentAlbum = useWorkspaceStore.use.currentAlbum()
  const checkedMedia = useWorkspaceStore.use.checkedMedia()
  const isDownloadInProgress = useWorkspaceStore.use.isDownloadInProgress()
  const setIsDownloadInProgress =
    useWorkspaceStore.use.setIsDownloadInProgress()
  const toast = useToast()
  const { recordTimeEvent } = useRumTimedEvent(
    CUSTOM_RUM_EVENTS.multipleFilesDownloadTime,
  )

  const toastIdRef = useRef<ToastId>() // To close toast that displays an intermediate status for downloads

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current)
    }
  }

  const handleDownloadError = (error: WretchError | Error) => {
    // Provide feedback to user via progress tile
    let errorMessage =
      'Could not download files from server. Please try again later.'
    if (error instanceof WretchError) {
      errorMessage = (error.json as { message: string }).message
    } else if (error.message) {
      errorMessage = error.message
    }

    toast({
      title: errorMessage,
      status: 'error',
    })
  }

  const triggerDownloadElement = (downloadUrl: string, fileName?: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = downloadUrl
    downloadLink.download = fileName ? fileName : '' // if doesn't exist, still sets download prop to allow download from component
    downloadLink.click()

    // Cleanup of anchor and URL
    window.URL.revokeObjectURL(downloadLink.href)
    downloadLink.remove()
  }

  const handleSingleDownload = (url: string, photoId: string) => {
    api
      .url(`${url}/single`)
      .post({
        photoId,
      })
      .text()
      .then((downloadUrl) => {
        triggerDownloadElement(downloadUrl)

        // Final toast to user to indicate that download was triggered on their browser.
        closeToast()
        toastIdRef.current = toast({
          title: 'Download starting!',
        })
      })
      .catch((error: WretchError | Error) => {
        closeToast()
        handleDownloadError(error)
      })
      .finally(() => {
        setIsDownloadInProgress(false)
      })
  }

  const handleMultipleDownloads = (url: string) => {
    const startTime = Date.now()
    api
      .url(`${url}/multiple`)
      .post({
        photoIds: Array.from(checkedMedia.ids),
      })
      .blob()
      .then((blob) => {
        const currentTimestamp = format(new Date(), 'yyyy-MM-dd_HHmmss')
        const fileName = `pinpoint-downloads-${currentTimestamp}.zip`
        const downloadUrl = window.URL.createObjectURL(blob)
        triggerDownloadElement(downloadUrl, fileName)

        // Final toast to user to indicate that download was completed.
        closeToast()
        toastIdRef.current = toast({
          title: 'Download completed!',
        })
        recordTimeEvent({
          startTime,
          isSuccessful: true,
          metadata: {
            numOfFiles: checkedMedia.ids.size,
            sizeOfFiles: checkedMedia.totalSize,
          },
        })
      })
      .catch((error: WretchError | Error) => {
        closeToast()
        handleDownloadError(error)
        recordTimeEvent({
          startTime,
          isSuccessful: false,
          metadata: {
            numOfFiles: checkedMedia.ids.size,
            sizeOfFiles: checkedMedia.totalSize,
          },
        })
      })
      .finally(() => {
        setIsDownloadInProgress(false)
      })
  }

  return () => {
    // verify if there are any inflights download before init
    if (isDownloadInProgress) return

    // Trigger a toast with a spinner to notify the user that Pinpoint is processing the request. Without it, for large
    // downloads, there may be an awkward period where the user does not see any response on the UI, and may think that
    // the download request did not go through.
    if (currentWorkspace === null || currentAlbum === null) {
      toast({
        title: `There was an error, please try again`,
        status: 'error',
      })
      console.error('currentWorkspace is not initialised')
      return
    }

    toastIdRef.current = toast({
      title: 'Download in progress...',
      duration: null,
      status: 'loading',
      isClosable: false,
    })

    // Ensure only one download happens at a time
    setIsDownloadInProgress(true)

    const baseDownloadApiUrl = `${generateWorkspacePathPrefix(
      currentWorkspace.id,
      currentAlbum.id,
    )}/photos/download`

    if (checkedMedia.ids.size === 1) {
      try {
        const photoId = Array.from(checkedMedia.ids)[0]
        handleSingleDownload(baseDownloadApiUrl, photoId)
      } catch (error) {
        // in case of indexing errors
        console.log(error)
        throw new Error(
          'Something went wrong. Please refresh the page and try again.',
        )
      }
    } else {
      handleMultipleDownloads(baseDownloadApiUrl)
    }
  }
}

export const useWorkspaceStore = createSelectors(WorkspaceStore)
