import { Divider, Flex, Link, Stack, Wrap } from '@chakra-ui/react'

import { ReactComponent as PinpointLogo } from '~assets/pinpoint-logo.svg'

import { FooterContainerProps, FooterVariantProps } from './common/types'

/** Desktop only compact footer variant */
export const CompactFooter = ({
  footerIconLinks,
  footerLinks,
  appLink,
  containerProps,
}: FooterVariantProps): JSX.Element => {
  return (
    <CompactFooter.Container padding="1.5" {...containerProps}>
      <Stack
        direction="row"
        h="2.25rem"
        align="center"
        spacing="1.5rem"
        paddingStart={{ base: '12px', lg: '64px' }}
      >
        <Link
          isExternal
          textDecoration={'none'}
          title="Link to application homepage"
          href={appLink}
        >
          <PinpointLogo width="6rem" height="1.5rem" />
        </Link>
        <Divider orientation="vertical" color={'neutral.300'} />

        <Wrap
          flex={1}
          shouldWrapChildren
          textStyle="body-2"
          justify="flex-end"
          spacing="1rem"
        >
          {footerIconLinks?.map((footerIconLink, index) => (
            <Link
              key={index}
              isExternal
              title={footerIconLink.label}
              href={footerIconLink.href}
              color="base.content.strong"
            >
              <footerIconLink.Icon
                fill={'white'}
                height="1.5rem"
                width="auto"
              />
            </Link>
          ))}
        </Wrap>
      </Stack>
      <Wrap
        flex={1}
        shouldWrapChildren
        textStyle="body-2"
        spacing={{ base: '0.5rem', lg: '1.5rem' }}
        justify="flex-end"
        paddingEnd={{ base: '12px', lg: '64px' }}
        ml="2rem"
      >
        {footerLinks?.map(({ label, href }, index) => (
          <Link
            isExternal
            m="-0.25rem"
            key={index}
            variant="standalone"
            w="fit-content"
            href={href}
          >
            {label}
          </Link>
        ))}
      </Wrap>
    </CompactFooter.Container>
  )
}

CompactFooter.Container = function CompactFooterContainer({
  children,
  ...props
}: FooterContainerProps): JSX.Element {
  return (
    <Flex
      align="center"
      width="100%"
      justify="space-between"
      flexDir="row"
      as="footer"
      {...props}
    >
      {children}
    </Flex>
  )
}
