import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const modalhelper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = modalhelper.definePartsStyle({
  dialog: {
    backgroundColor: 'base.canvas.default',
    borderRadius: 'xl',
  },
  body: {
    '::-webkit-scrollbar': {
      width: '4px',
    },
    '::-webkit-scrollbar-track': {
      background: 'base.canvas.alt',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'base.canvas.overlay',
      borderRadius: 4,
    },
    // For firefox
    scrollbarColor: `${'base.canvas.alt'} ${'base.canvas.overlay'}`,
  },
  overlay: {
    backdropFilter: 'auto',
    backdropBlur: '2px',
    backdropInvert: '10%',
    background: 'none',
  },
})

export const modalTheme = modalhelper.defineMultiStyleConfig({
  baseStyle,
})
