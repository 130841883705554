import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { anatomy } from '@chakra-ui/theme-tools'

import { spacing } from '../generated/spacing'

const parts = anatomy('attachment').parts('container')
const attachmentHelper = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = attachmentHelper.definePartsStyle({
  container: {
    paddingStart: spacing[6],
    paddingEnd: spacing[6],
    width: '100%',
  },
})
export const attachmentTheme = attachmentHelper.defineMultiStyleConfig({
  baseStyle,
})
