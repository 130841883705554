import wretch from 'wretch'

import { UNAUTHORIZED_EVENT } from '~constants/events'

import { STORAGE_LOGGED_IN_KEY } from '../features/auth'

/**
 * Default API client pointing to backend.
 * Automatically catches 401 errors and invalidates authentication state.
 */
export const api = wretch('/api/v1')
  .catcher(401, () => {
    window.dispatchEvent(new Event(UNAUTHORIZED_EVENT))
    window.localStorage.removeItem(STORAGE_LOGGED_IN_KEY)
    window.location.reload()
  })
  .errorType('json')

export type IApiError = {
  statusCode: number
  message: string
  error: string
}
