import { differenceInSeconds } from 'date-fns'

import { useRum } from '~lib/rum'

type RecordTimeEventInput = {
  startTime: number
  isSuccessful?: boolean
  metadata: Record<string, unknown>
}

export const useRumTimedEvent = (eventType: string) => {
  const { awsRum } = useRum()

  const recordTimeEvent = ({
    startTime,
    isSuccessful,
    metadata,
  }: RecordTimeEventInput) => {
    awsRum?.recordEvent(eventType, {
      timeTaken: differenceInSeconds(Date.now(), startTime),
      isSuccessful,
      ...metadata,
    })
  }

  return { recordTimeEvent }
}
