import { ResponsiveValue } from '@chakra-ui/react'

import {
  DEFAULT_FOOTER_ICON_LINKS,
  DEFAULT_FOOTER_LINKS,
  DEFAULT_SOCIAL_MEDIA_LINKS,
} from './common/constants'
import { CompactFooter } from './CompactFooter'
import { FullFooter } from './FullFooter'

interface AppFooterProps {
  variant?: ResponsiveValue<'full' | 'compact'>
  bg?: string
}

export const AppFooter = (props: AppFooterProps): JSX.Element => {
  const { variant, bg } = props
  const Footer = variant === 'compact' ? CompactFooter : FullFooter

  return (
    <Footer
      footerIconLinks={DEFAULT_FOOTER_ICON_LINKS}
      socialMediaLinks={DEFAULT_SOCIAL_MEDIA_LINKS}
      footerLinks={DEFAULT_FOOTER_LINKS}
      appLink={window.location.origin}
      appName="Pinpoint"
      tagline="Making image search easy"
      containerProps={{
        px: 2,
        bg: bg ?? 'transparent',
      }}
      {...props}
    />
  )
}
