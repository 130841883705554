/**
 * Do not edit directly
 * Generated on Fri, 19 Jul 2024 07:50:25 GMT
 */

export const colors = {
  standard: {
    white: '#ffffff',
    black: '#000000',
    darkgray: '#111111',
  },
  base: {
    canvas: {
      default: '#0B0C0E',
      alt: '#17191C',
      backdrop: '#22252A',
      'brand-subtle': '#3F34B0',
      inverse: '#D7D8DA',
      overlay: 'rgba(46, 50, 56, 0.2)',
    },
    content: {
      default: '#D7D8DA',
      strong: '#EBECEC',
      medium: '#B0B2B5',
      brand: '#8C82EF',
      inverse: '#EBECEC',
    },
    divider: {
      subtle: '#17191C',
      medium: '#22252A',
      strong: '#2E3238',
      inverse: '#ffffff',
      brand: '#CBCBEB',
    },
  },
  interaction: {
    main: {
      default: '#8C82EF',
      hover: '#A19AED',
      active: '#B5B3E9',
    },
    'main-subtle': {
      default: '#756CEA',
      hover: '#8C82EF',
      active: '#A19AED',
    },
    sub: {
      default: '#393E46',
      hover: '#2E3238',
      active: '#22252A',
    },
    'sub-subtle': {
      default: '#17191C',
      hover: '#22252A',
      active: '#2E3238',
    },
    critical: {
      default: '#e74f4f',
      hover: '#ee8080',
      active: '#f4acac',
    },
    'critical-subtle': {
      default: '#571717',
      hover: '#721f1f',
      active: '#852424',
    },
    warning: {
      default: '#E2B73E',
      hover: '#FFDA68',
      active: '#fffae1',
    },
    'warning-subtle': {
      default: '#3f2b00',
      hover: '#533800',
      active: '#624200',
    },
    success: {
      default: '#2D978C',
      hover: '#63B1AA',
      active: '#98CCC7',
    },
    'success-subtle': {
      default: '#073430',
      hover: '#09463F',
      active: '#0A524B',
    },
    'neutral-subtle': {
      default: '#0B0C0E',
      hover: '#17191C',
      active: '#22252A',
    },
    muted: {
      main: {
        hover: '#22252A',
        active: '#2E3238',
      },
      sub: {
        hover: '#3F34B0',
        active: '#B5B3E9',
      },
      critical: {
        hover: '#571717',
        active: '#721f1f',
      },
      neutral: {
        hover: '#0B0C0E',
        active: '#17191C',
      },
    },
    tinted: {
      main: {
        hover: 'rgba(176, 178, 181, 0.04)',
        active: 'rgba(140, 130, 239, 0.12)',
      },
      critical: {
        hover: 'rgba(192, 52, 52, 0.04)',
        active: 'rgba(192, 52, 52, 0.12)',
      },
      neutral: {
        hover: 'rgba(0, 0, 0, 0.1)',
        active: 'rgba(0, 0, 0, 0.2)',
      },
      inverse: {
        hover: 'rgba(34, 37, 42, 0.6)',
        active: 'rgba(57, 62, 70, 0.5)',
      },
      sub: {
        hover: 'rgba(57, 62, 70, 0.04)',
        active: 'rgba(57, 62, 70, 0.12)',
      },
    },
    support: {
      unselected: '#393E46',
      selected: '#61656B',
      'unselected-strong': '#61656B',
      disabled: '#888B90',
      'disabled-content': '#B0B2B5',
      placeholder: '#B0B2B5',
    },
    links: {
      default: '#B5B3E9',
      hover: '#CBCBEB',
      'neutral-default': '#B0B2B5',
      'neutral-hover': '#D7D8DA',
      'inverse-default': '#17191C',
      'inverse-hover': '#22252A',
    },
    neutral: {
      default: '#0B0C0E',
      hover: '#17191C',
      active: '#22252A',
    },
  },
  utility: {
    feedback: {
      info: '#82ABF7',
      'info-subtle': '#072A69',
      warning: '#FFDA68',
      'warning-subtle': '#3f2b00',
      success: '#2D978C',
      'success-subtle': '#073430',
      critical: '#e74f4f',
      'critical-subtle': '#571717',
    },
    'focus-default': '#B5B3E9',
    'focus-inverse': '#111111',
    'input-prefilled': '#3f2b00',
    ui: '#17191C',
    'ui-clear': 'rgba(17, 17, 17, 0)',
  },
  brand: {
    primary: {
      '50': '#E9E9F4',
      '100': '#E0E0F0',
      '200': '#CBCBEB',
      '300': '#B5B3E9',
      '400': '#A19AED',
      '500': '#8C82EF',
      '600': '#756CEA',
      '700': '#6156E8',
      '800': '#503CEA',
      '900': '#3F34B0',
    },
    secondary: {
      '50': '#EBECEC',
      '100': '#D7D8DA',
      '200': '#B0B2B5',
      '300': '#888B90',
      '400': '#61656B',
      '500': '#393E46',
      '600': '#2E3238',
      '700': '#22252A',
      '800': '#17191C',
      '900': '#0B0C0E',
    },
  },
  purple: {
    '50': '#E9E9F4',
    '100': '#E0E0F0',
    '200': '#CBCBEB',
    '300': '#B5B3E9',
    '400': '#A19AED',
    '500': '#8C82EF',
    '600': '#756CEA',
    '700': '#6156E8',
    '800': '#503CEA',
    '900': '#3F34B0',
  },
  grey: {
    '50': '#EBECEC',
    '100': '#D7D8DA',
    '200': '#B0B2B5',
    '300': '#888B90',
    '400': '#61656B',
    '500': '#393E46',
    '600': '#2E3238',
    '700': '#22252A',
    '800': '#17191C',
    '900': '#0B0C0E',
  },
  blue: {
    '50': '#F7F9FE',
    '100': '#E7EFFC',
    '200': '#ACC7FA',
    '300': '#82ABF7',
    '400': '#568DF4',
    '500': '#1361F0',
    '600': '#0D4FCA',
    '700': '#0B44AC',
    '800': '#093890',
    '900': '#072A69',
  },
  red: {
    '50': '#fef7f7',
    '100': '#FBE9E9',
    '200': '#f4acac',
    '300': '#ee8080',
    '400': '#e74f4f',
    '500': '#c03434',
    '600': '#992a2a',
    '700': '#852424',
    '800': '#721f1f',
    '900': '#571717',
  },
  green: {
    '50': '#F5FAFA',
    '100': '#E2EEED',
    '200': '#98CCC7',
    '300': '#63B1AA',
    '400': '#2D978C',
    '500': '#0F796F',
    '600': '#0C5F57',
    '700': '#0A524B',
    '800': '#09463F',
    '900': '#073430',
  },
  yellow: {
    '50': '#fffae1',
    '100': '#FFDA68',
    '200': '#E2B73E',
    '300': '#C4992A',
    '400': '#A77C18',
    '500': '#8B6005',
    '600': '#704C00',
    '700': '#624200',
    '800': '#533800',
    '900': '#3f2b00',
  },
  slate: {
    '50': '#f8f9fa',
    '100': '#e9eaee',
    '200': '#babecb',
    '300': '#9aa0b3',
    '400': '#7b849c',
    '500': '#5d6785',
    '600': '#465173',
    '700': '#3c4764',
    '800': '#333c56',
    '900': '#272d41',
  },
  skin: {
    '1base': '#FFCAA4',
    '1shadow': '#F5B896',
    '2base': '#EBA687',
    '2shadow': '#E29B7B',
    '3base': '#D98F6F',
    '3shadow': '#CE8361',
    '4base': '#BF7657',
    '4shadow': '#AF6D53',
    '5base': '#9F634F',
    '5shadow': '#8B5544',
    '6base': '#764738',
    '6shadow': '#633D32',
  },
}
