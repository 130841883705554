import { attachmentTheme } from './attachment'
import { bannerTheme } from './banner'
import { breadcrumbTheme } from './breadcrumb'
import { calendarTheme } from './calendar'
import { drawerTheme } from './drawer'
import { kbdTheme } from './kbd'
import { menuTheme } from './menu'
import { modalTheme } from './modal'

export const components = {
  Menu: menuTheme,
  Attachment: attachmentTheme,
  Calendar: calendarTheme,
  Kbd: kbdTheme,
  Breadcrumb: breadcrumbTheme,
  Modal: modalTheme,
  Banner: bannerTheme,
  Drawer: drawerTheme,
}
