export const USER_NOT_FOUND_MESSAGE = 'User does not exist'

export const EMAIL_ERROR_CONTACT_US_MESSAGE =
  'If the error persists, contact us at pinpoint@dotc.gov.sg'

export const FILE_TYPE_MISMATCH_ERROR_MESSAGE = 'file type mismatch'
export const CONFIRM_UPLOAD_GENERIC_ERROR = 'error verifying upload of file'
export const FILE_UPLOAD_ERROR = 'File failed to upload'
export const FILE_NOT_FOUND_IN_S3_ERROR = 'File not found in S3'
export const MISSING_PARTS_ERROR = 'One or more parts not uploaded'
