import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyle,
} from '@chakra-ui/styled-system'

// eslint-disable-next-line
const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys)

const $bg = cssVar('menu-bg')

const baseStyleList = defineStyle({
  bg: 'base.canvas.alt', //Require this on top of bg.variable for opacity
  [$bg.variable]: 'base.canvas.alt', //Strangely using canvas.base.default leads to transparency//
  borderRadius: '0.5rem',
  '& :hover': {
    textColor: 'interaction.main.hover',
  },
})

const baseStyle = definePartsStyle({
  list: baseStyleList,
})

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
})
