import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const bannerHelper = createMultiStyleConfigHelpers(['banner'])

const baseStyle = bannerHelper.definePartsStyle({
  banner: { div: { background: 'interaction.main.default' } },
})
export const bannerTheme = bannerHelper.defineMultiStyleConfig({
  baseStyle,
})
